/*
    Name:     Kimbie (dark)
    Author:   Jan T. Sott
    License:  Creative Commons Attribution-ShareAlike 4.0 Unported License
    URL:      https://github.com/idleberg/Kimbie-highlight.js
*/

/* Kimbie Comment */
.hljs-comment,
.hljs-quote {
  color: #d6baad;
}

/* Kimbie Red */
.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-meta {
  color: #dc3958;
}

/* Kimbie Orange */
.hljs-number,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-deletion,
.hljs-link {
  color: #f79a32;
}

/* Kimbie Yellow */
.hljs-title,
.hljs-section,
.hljs-attribute {
  color: #f06431;
}

/* Kimbie Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #889b4a;
}

/* Kimbie Purple */
.hljs-keyword,
.hljs-selector-tag,
.hljs-function {
  color: #98676a;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #221a0f;
  color: #d3af86;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
